html {
  margin: 0px;
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Thasadith", "Titillium Web", sans-serif;
  width: 100vw;
  margin: 0;
  overflow-x: hidden;
  /* color: #16d355; */
}

@font-face {
  font-family: "Mosk";
  src: local("Mosk"), url(./fonts/mosk-600.ttf) format("woff");
}

@font-face {
  font-family: "Jenthill";
  src: local("Jenthill"), url(./fonts/jenthill.woff) format("woff");
}

a {
  text-decoration: none;
  color: inherit;
}

#root {
  width: 100%;
  height: 100%;
}

footer .mobile {
  background: #0555ea;
  color: #fff;
}

footer .mobile div {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 800px) {
  footer .mobile {
    display: flex;
  }

  footer .desktop {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  footer .mobile {
    display: none;
  }

  footer .desktop {
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
  }
}

.link-dark-mode {
  color: #61dafb;
  font-weight: bold;
}

.link-light-mode {
  color: #4946ec;
  font-weight: bold;
}
